import React from "react";
import { graphql, StaticQuery } from "gatsby";
import theme from "../theme/theme.yaml";
import Article from "../components/Article";
import Headline from "../components/Article/Headline";
import Seo from "../components/Seo";
import { FaTag, FaRss, FaPaperPlane } from "react-icons/fa";
import config from "../../content/meta/config";
import { Link } from "gatsby";

const GamePage = (props) => {
  return (
    <React.Fragment>
      <Article theme={theme}>
        <header>
          <Headline title="PCWeb Tips" theme={theme} />
        </header>

        <section
          style={{
            margin: "2em 0",
            padding: "2em",
            backgroundColor: "#f5f5f5",
            borderRadius: "5px",
            lineHeight: "1.5",
          }}
        >
          <h1 style={{ textAlign: "center", color: "#333", marginBottom: "1em" }}>
            ほとんど自分のメモ代わりに近いページです
          </h1>
          <div style={{ textAlign: "justify" }}>
            <p>
              自分で試してみて良かったTipsや、あまり一般的でないTipsを誰でも使えるように解説しています。
            </p>
          </div>
        </section>
        <section
          style={{
            margin: "2em 0",
            padding: "2em",
            backgroundColor: "#f5f5f5",
            borderRadius: "5px",
            lineHeight: "1.5",
          }}
        >
          <div>
            <h2 style={{ color: "#333", marginBottom: "0.5em" }}>PC Tips</h2>
            <ul style={{ listStyleType: "circle", paddingLeft: "20px" }}>
              <li><Link to="/pcweb-tips/asio4all-manual">Windows 10/8.1/8/7/Vistaで音質を改善する（オンボードサウンドでASIOを使う）</Link></li>
              <li><Link to="/pcweb-tips/hires-audio">最上級音質アニソンで毎日ハイテンション！ハイレゾ生活のための3つのコツ</Link></li>
            </ul>

            <h2 style={{ color: "#333", marginTop: "2.0em", marginBottom: "1em" }}>Web Tips</h2>
            <ul style={{ listStyleType: "circle", paddingLeft: "20px", marginBottom: "0.5em" }}>
              <li>nginxで最速Webサーバをつくる（予定）</li>
              <li>Let’s encryptでSSL&HTTP2のWebサーバをつくる（予定）</li>
              <li>Qualys SSL Reportでセキュリティ評価「A＋」なWebサーバをつくる（予定）</li>
              {/* 他のコンテンツやコンポーネントを追加 */}
            </ul>
          </div>
        </section>
      </Article>
      <Seo
      title="PC/Web Tips | ゲーム機の凄いとこまとめサイト - Digital Colors"
      description="ほとんど自分のメモ代わりに近いページです。 自分で試してみて良かったTipsや、あまり一般的でないTipsを誰でも使えるように解説しています。"
      ogpimage=""
    />
    </React.Fragment>
  );
};

export default GamePage;
